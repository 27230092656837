.header {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
  width: 87%;
  align-items: center;
  justify-content: space-between;
  padding: 3% 3.65%;
  margin: 0.7%;
}

.header_logo {
  flex: 1;
}

.header_logo img {
  width: 6.25em;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.social_media {
  color: white;
  font-size: 15px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.social_media img {
  width: 17px;
}

.social_media > * {
  padding-bottom: 13px;
  margin: 5%;
}

.header_elements {
  flex: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header_elements ul {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  justify-content: center;
  padding-left: 8%;
}

.header_elements ul li {
  margin-right: 5%;
  cursor: pointer;
  line-height: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 13.5px;
  font-family: "Montserrat Alternates";
  color: white;
}

li a {
  padding-bottom: 13px;
}

.header_elements ul li a::after {
  content: "";
  margin-top: 13px;
  width: 50%;
  height: 2px;
  display: block;
  background-color: white;
  transform: translateX(50%);
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.header_elements ul li a:hover:after,
.header_elements ul li a.selected::after {
  opacity: 1;
}

.burgermenu {
  display: none;
}

@media only screen and (max-width: 850px) {
  body > * {
    width: 100vw !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow-x: hidden;
  }
  .header {
    background-color: var(--black) !important;
    width: 100vw !important;
    height: 15vh !important;
    margin: 0 !important;
    position: fixed !important;
    z-index: 15 !important;
    padding: 0!important;
  }

  .header_elements {
    position: fixed !important;
    left: -100% !important;
    height: calc(100vh + 100px) !important;
    top: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: var(--black) !important;
    width: 250px !important;
    border-right: 1px solid rgb(156, 140, 140) !important;
    transition: all .5s ease !important;
  }

  .header_elements ul {
    display: flex !important;
    flex-direction: column !important;
  }

  .header .social_media {
    display: none !important;
  }

  .header img {
    position: relative !important;
    top: 0 !important;
    margin-left: 10vw !important;
    transition: all .6s ease-in-out !important;
  }

  .header .burgermenu {
    display: flex !important;
    flex-direction: column !important;
    margin: auto 20% !important;
    cursor: pointer !important;
  }

  .header .burgermenu .line {
    height: 3px;
    width: 30px;
    border-radius: 2px;
    background-color: var(--orange);
    margin: 3px;
    transition: all .5s ease;
  }

  .header .burgermenu .line:last-child::after {
    display: block;
    content: "";
    height: 3px;
    width: 30px;
    border-radius: 2px;
    background-color: var(--orange);
    margin-top: 9px;
    transition: all .1s ease;
  }

  .header.active .burgermenu .line:first-child{
    transform: rotate(-47deg);
    translate: -.5px 250%;
    height: 4px;
    
    border-radius: 4px;
  }

  .header.active .burgermenu .line:last-child{
    transform: rotate(47deg);
    height: 4px;
    border-radius: 4px;
  }

  .header.active .burgermenu .line:last-child::after {
    height: 0;
  }

  .header.active .header_elements {
    left: 0 !important;
  }

  .header.active .header_logo img {
    position: relative !important;
    top: 70px !important;
    z-index: 20 !important;
    width: 170px !important;
  }

  .header.active .header_elements ul li a::after {
    width: 13% !important;
    margin-bottom: 15px !important;
  }

  .header.active .social_media {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    position: fixed !important;
    bottom: 10px !important;
    left: 10px !important;
    width: 200px !important;
  }
}
